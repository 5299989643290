import moment from "moment"
import { capitalize } from '@core/utils/filter'
import { formatMoney } from "@/helper/function"
import router from "@/router"

export default {
  "clients": [
   
    // {
    //   key: "last_sign_in_at",
    //   settingLabel: "Last Sign In At",
    //   formatter: (value, key, item) => {
    //     return `${moment(item.user.last_sign_in_at).format('DD/MM/Y')} <small class='text-secondary'>${moment(item.user.last_sign_in_at).format('hh:mm:ss')}<small>`
    //   },
    //   sortable: true,
    //   sort_key: "users.last_sign_in_at",
    // },
    {
      key: "id", 
      label: "Client ID", 
      settingLabel: "ClientID",
      sortable: true,
      sort_key: "clients.id",
      filterMaxWidth: '60px',
      thClass: 'py-75 pl-2',
      tdClass: 'py-75 pl-2',
      filterClass: 'py-50',
      filterStyle: ''
    },
    {
      key: "full_name",
      label: "Name",
      settingLabel: "Name",
      sortable: true,
      sort_key: "users.first_name,users.middle_name,users.last_name",
    
    },
    {
      key: "email",
      settingLabel: "Email",
      formatter: (value, key, item) => {
        return item.user.email
      },
      sortable: true,
      sort_key: "users.email",
      onclick: (item) => {
        window.location.href = "mailto:" + item.user.email
      }
    },
    {
      key: "category",
      settingLabel: "Category",
      filter: {
        type: "select",
        options: [
          {value: "All", text: "All"}, 
          {value: "Trader", text: "Trader"}, 
          {value: "Partner", text: "Partner"}
        ]
      },
    },
    {
      key: "phone",
      settingLabel: "Phone",
      formatter: (value, key, item) => {
        return item.user.personal_info.mobile_phone
      },
      sortable: true,
      sort_key: "users.personal_info ->> 'mobile_phone'"
    },
    {
      key: "country",
      settingLabel: "Country",
      sortable: true,
      filterMaxWidth: '50px'
    },
    {
      key: "funded",
      settingLabel: "Funded",
      formatter: (value, key, item) => {
        return value ? "Yes" : "No"
      },
      filter: {
        type: "select",
        options: [
          {value: "All", text: "All"}, 
          {value: "yes", text: "Yes"}, 
          {value: "no", text: "No"}
        ]
      },
    },
    {
      key: "account_manager_id",
      label: "AM ID",
      settingLabel: "AM ID",
      isHTML: true,
      formatter: (value, key, item) => {
        return `<div>${value ? value : "-"}</div>`;
      },
      filterMaxWidth: '60px'
    },
    {
      key: "ib_id",
      label: "IB ID",
      settingLabel: "IB ID",
      formatter: (value, key, item) => {
        return value ?? "-"
      },
      filterMaxWidth: '60px'
    },
    {
      key: "account_equity",
      label: "Trading ($)",
      settingLabel: "Trading Equity",
      formatter: (value, key, item) => {
        return formatMoney(value, 2)
      },
      filter: {
        type: "none"
      },
      sort_key: "SUM(accounts ->> 'equity')"
    },
    {
      key: "wallet_equity",
      label: "Wallet ($)",
      settingLabel: "Wallet Equity",
      formatter: (value, key, item) => {
        return formatMoney(value, 2)
      },
      filter: {
        type: "none"
      },
    },
    {
      key: "total_equity",
      label: "Total Equity ($)",
      settingLabel: "Total Equity",
      formatter: (value, key, item) => {
        return formatMoney(parseFloat(item.account_equity) + parseFloat(item.wallet_equity), 2)
      },
      filter: {
        type: "none"
      },
    },
    {
      key: "state",
      label: "Status",
      settingLabel: "Status",
      isHTML: true,
      formatter: (value, key, item) => {
        let textClass = item.state == "active" ? "text-success": "text-warning";
        return `<div class="${textClass}">${capitalize(item.state)}</div>`;
      },
      filter: {
        type: "select",
        options: [
          {value: "All", text: "All"}, 
          {value: "active", text: "Active"}, 
          {value: "lock", text: "Lock"},
          {value: "disabled", text: "Disabled"}
        ]
      },
    },
    {
      key: "auto_transfer",
      label: "AT",
      settingLabel: "Auto Transaction (AT)",
      isHTML: true,
      formatter: (value, key, item) => {
        return `<div class="${value ? "text-success": "text-warning"}">${value ? "Yes" : "No"}</div>`;
      },
      filter: {
        type: "select",
        options: [
          {value: "All", text: "All"}, 
          {value: true, text: "Yes"}, 
          {value: false, text: "No"}
        ]
      },
    },
    {
      key: "created_at",
      settingLabel: "Verified Date",
      label: "Verified Date",
      formatter: (value, key, item) => {
        return moment(value).format("DD/MM/Y")
      },
      filter: {
        type: "date",
        default: {
          filter: "",
          type: 'inRange',
          filterType: 'date'
        }
      },
      sortable: true,
    },
    {
      key: "filter",
      label: "",
      thStyle: "width: 30px; padding: none"
    }
  ],
  "accounts": [
    {
      key: "login",
      label: "Login",
      settingLabel: "Login",
      formatter: (value, key, item) => item.account_id
   
    },
    {
      key: "platform",
      settingLabel: "Platform",
      formatter: (value, key, item) => {
        return `${item.platform}/${item.server}`
      },
    },
    {
      key: "full_name",
      label: "Name",
      settingLabel: "Name",
      formatter: (value, key, item) => item.user.full_name
    },
    {
      key: "email",
      settingLabel: "Email",
      formatter: (value, key, item) => {
        return item.user.email
      }
    },
    {
      key: "group",
      label: "Group",
      settingLabel: "Trading Group",
      formatter: (value, key, item) => {
        return item.account_group
      },
    },
    {
      key: "currency",
      settingLabel: "Currency"
    },
    {
      key: "country",
      settingLabel: "Country",
      formatter: (value, key, item) => {
        return item.user.country
      },
    },
    {
      key: "funded",
      settingLabel: "Funded",
      formatter: (value, key, item) => {
        return value ? "Yes" : "No"
      },
      filter: {
        type: "select",
        options: [
          {value: "All", text: "All"}, 
          {value: "yes", text: "Yes"}, 
          {value: "no", text: "No"}
        ]
      }
    },
    {
      key: "funding_source",
      settingLabel: "Funding Source (FS)",
      label: "FS"
    },
    {
      key: "am_id",
      label: "AM ID",
      settingLabel: "AM ID",
      formatter: (value, key, item) => {
        return item.user.client?.account_manager_id ?? "-"
      }
    },
    {
      key: "ib_id",
      label: "IB ID",
      settingLabel: "IB ID",
      formatter: (value, key, item) => {
        return item.ib?.id ?? "-"
      }
    },
    {
      key: "balance",
      settingLabel: "Balance",
      filter: {
        type: "none"
      }
    },
    {
      key: "credit",
      settingLabel: "Credit",
      filter: {
        type: "none"
      }
    },
    {
      key: "equity",
      settingLabel: "Equity",
      filter: {
        type: "none"
      }
    },
    {
      key: "margin_level",
      label: "Margin Lvl",
      settingLabel: "Margin Lvl",
      formatter: (value, key, item) => {
        return value ? formatMoney(value, 2) + "%" : "-"
      },
      filter: {
        type: "none"
      }
    },
    {
      key: "floating",
      label: "Floating P/L",
      settingLabel: "Floating P/L",
      formatter: (value, key, item) => {
        return ""
      },
      filter: {
        type: "none"
      }
    },
    {
      key: "state",
      label: "Status",
      settingLabel: "Status",
      isHTML: true,
      formatter: (value, key, item) => {
        return item.state == "active" ? "<div class='text-success'>Enabled</div>" : `<div class='text-warning'>${capitalize(item.state)}</div>`;
      },
      filter: {
        type: "select",
        options: [
          {value: "All", text: "All"}, 
          {value: "active", text: "Enabled"}, 
          {value: "locked", text: "Locked"},
          {value: "disabled", text: "Disabled"}
        ],
        default: {
          filter: "All",
          type: "equals"
        }
      }
    },
    {
      key: "trading",
      settingLabel: "Trading",
      isHTML: true,
      formatter: (value, key, item) => {
        return true ? "<div class='text-success'>Enable</div>" : "<div class='text-warning'>Disable</div>"
      },
      filter: {
        type: "select",
        options: [
          {value: "All", text: "All"}, 
          {value: "enable", text: "Enable"}, 
          {value: "disable", text: "Disable"}
        ],
        default: {
          filter: "All",
          type: "equals"
        }
      }
    },
    {
      key: "auto_transfer",
      label: "AT",
      settingLabel: "Auto Transaction (AT)",
      isHTML: true,
      formatter: (value, key, item) => {
        return `<div class="${value ? "text-success": "text-warning"}">${value ? "Yes" : "No"}</div>`;
      },
      filter: {
        type: "select",
        options: [
          {value: "All", text: "All"}, 
          {value: true, text: "Yes"}, 
          {value: false, text: "No"}
        ]
      },
    },
    {
      key: "created_at",
      label: "Created Date",
      settingLabel: "Created Date",
      formatter: (value, key, item) => {
        return moment(value).format("DD/MM/Y")
      },
      filter: {
        type: "date",
        default: {
          filter: '',
          type: 'inRange',
          filterType: 'date'
        }
      }
    },
    {
      key: "filter",
      lable: "",
    }
  ]
}