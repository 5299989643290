<template>
  <common-table
    :items="clients"
    :fields="fields"
    :perPage="perPage"
    :currentPage="currentPage"
    :selected="selected"
    @fetchItems="fetchItems"
    :showLoading="showLoading"
    @selectedChange="(newSelected) => $emit('selectedChange', newSelected)"
  >
    <template #cell(full_name)="{item}">
      <router-link
        :to="{
          name: 'client_details',
          params: { client_id: item.id, tab_key: 'summary' },
        }"
        >{{ item.full_name }}</router-link
      >
    </template>
  </common-table>
</template>

<script>
import CommonTable from "@/components/table/CommonTable";
import { mapGetters } from "vuex";

export default {
  components: {
    CommonTable,
  },

  props: {
    fields: Array,
    perPage: Number,
    currentPage: Number,
    selected: Array,
  },

  data() {
    return {
      showLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      clients: "client/clients",
    }),
  },

  methods: {
    async fetchItems(size, page, sort_by, filter_by) {
      try {
        this.showLoading = true;
        await this.$store.dispatch("client/fetchClients", {
          size,
          page,
          filter_by: filter_by,
          sort_by: sort_by,
        });
        await this.$store.dispatch(
          "client/fetchTradingEquity",
          this.clients.map((client) => client.id)
        );
        // this.$emit("setTotalRows", res.data.total); //Maybe will raise a bug but should fix later
      } catch (err) {
        console.error(err);
      } finally {
        this.showLoading = false;
      }
    },
  },

  mounted() {},
};
</script>
