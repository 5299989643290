import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export const flattenObject = function(obj, res = {}, prefix = "", set = (obj, index, val) => {obj[index] = val}) {
  Object.keys(obj).forEach((key) => {
    var newKey = prefix === "" ? key : `${prefix}.${key}`;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      flattenObject(obj[key], res, newKey, set);
    } else {
      set(res, newKey, obj[key]);
    }
  });
  return res;
}

export const unflattenObject = function(data) {
  let result = {};
  for (let i in data) {
    let keys = i.split(".");
    keys.reduce(function (r, e, j) {
      return (
        r[e] ||
        (r[e] = isNaN(Number(keys[j + 1]))
          ? keys.length - 1 == j
            ? data[i]
            : {}
          : [])
      );
    }, result);
  }
  return result;
}

const calcLabel = (key) => {
  function c([first, ...rest], locale = navigator.language) {
    if (!["of", "or", "for"].includes([first, ...rest].join("")))
      return [first.toLocaleUpperCase(locale), ...rest].join("");
    else return [first, ...rest].join("");
  }
  return key
    .split("_")
    .map((i) => {
      return c(i);
    })
    .join(" ");
}

const calcType = (o_type) => {
  if (o_type.type == "boolean")
    return {
      fieldType: "RadioButtons",
      options: o_type.options.map((item) => {
        return item.text;
      }),
    };
  if (o_type.enum) {
    return {
      fieldType: "SelectList",
      options: o_type.enum,
    };
  } else
    return {
      fieldType: "TextInput",
    };
}

const chunkArray = function(myArray) {
  let index = 0;
  let arrayLength = myArray.length;
  let tempArray = [];

  for (index = 0; index < arrayLength; index += 3) {
    let myChunk = myArray.slice(index, index + 3);
    tempArray.push(myChunk);
  }
  return tempArray;
}

export const calcSchema = function(x) {
  let result = [];
  if (Object.keys(x.properties).length > 1) {
    Object.keys(x.properties).map((key) => {
      result.push({
        ...calcType(x.properties[key]),
        name: key,
        label: calcLabel(key),
        cardType: "FieldText",
      });
    });
  }
  else {
    let properties = x.properties[Object.keys(x.properties)[0]].properties;
    Object.keys(properties).map((key) => {
      result.push({
        ...calcType(properties[key]),
        name: Object.keys(x.properties)[0] + "." + key,
        label: calcLabel(key),
        cardType: "FieldText",
      });
    });
  }
  return [chunkArray(result)];
}

export const notifySuccess = (title, text) => {
  Vue.$toast({
    component: ToastificationContent,
    position: "top-right",
    props: {title, text, icon: 'CheckIcon', variant: "success"},
  });
}

export const notifyError = (title, text) => {
  Vue.$toast({
    component: ToastificationContent,
    position: "top-right",
    props: {title, text, icon: 'AlertCircleIcon', variant: "danger"},
  });
}

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
      console.error(e)
  }
};