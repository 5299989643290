<template>
  <common-table
    :items="accounts"
    :fields="fields"
    :perPage="perPage"
    :currentPage="currentPage"
    :selected="selected"
    @fetchItems="fetchItems"
    :showLoading="showLoading"
    @selectedChange="(newSelected)  => $emit('selectedChange', newSelected)"
  />
</template>

<script>
import { mapGetters } from "vuex";
import CommonTable from "@/components/table/CommonTable"

export default {
  components: {
    CommonTable
  },

  props: {
    fields: Array,
    perPage: Number,
    currentPage: Number,
    selected:Array
  },

  data() {
    return {
      showLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      accounts: "account/accounts",
    }),
  },

  methods: {
    async fetchItems(size, page, sort_by, filter_by) {
      this.showLoading = true
      await this.$store.dispatch("account/fetchAccounts", {
        size,
        page,
        sort_by,
        filter_by
      }).then((res) => {
        this.$emit("setTotalRows", res.data.total)
        // console.log(res.data.accounts)
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.showLoading = false
      })
    },
  },

  mounted() {
  },
};
</script>