<template>
  <div class="h-100">
    <b-card class="h-100">
      <div class="d-flex justify-content-between" style="padding: 8px 16px">
        <div></div>

        <div class="d-flex align-items-center">
          <font-awesome-icon
            class="mr-2 btn-icon"
            :icon="['fal', 'cog']"
            size="1x"
            @click="
              () => {
                $bvModal.show('table-setting-modal');
              }
            "
          />
          <table-setting-modal
            :fields="tableFields[currentItem].filter((f) => f.settingLabel)"
            :excludedFields="excludedFields"
            @setExcludedFields="setExcludedFields"
          />
          <b-dropdown
            :text="itemTypes[currentItem].label"
            variant="flat-dark"
            right
            toggle-class="my-dropdown-width-fixed-width"
            class="border"
            size="sm"
          >
            <b-dropdown-item
              v-for="(value, index) in itemTypes"
              :key="index"
              @click="onClick(value, index)"
            >
              {{ value.label }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="custom-table-height" :style="tableCssVars">
        <component
          :key="currentItem + ' ' + tableKey"
          :fields="
            tableFields[currentItem].filter(
              (f) => !excludedFields.includes(f.key)
            )
          "
          :is="currentTable"
          :perPage="perPage"
          :currentPage="currentPage"
          :selected="selected"
          @setTotalRows="(total) => (totalRows = total)"
          @setCurrentPage="(page) => (currentPage = page)"
          @selectedChange="(newSelected) => (selected = newSelected)"
        >
        </component>
      </div>

      <div class="d-flex justify-content-between py-50 font-small-3 border-top">
        <div class="my-auto ml-2">
          Show
          <b-dropdown
            :text="String(perPage)"
            variant="outline-secondary"
            toggle-class="custom-dropdown"
            class="dropdown-borderless"
          >
            <b-dropdown-item
              v-for="item in [10, 20, 50, 100]"
              :key="item"
              @click="perPage = item"
            >
              {{ item }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="d-flex">
          <div class="my-auto mr-2">
            Showing
            {{ Math.min(totalRows, (currentPage - 1) * perPage + 1) }} to
            {{ Math.min(currentPage * perPage, totalRows) }} of
            {{ totalRows }} entries
          </div>
          <b-pagination
            size="sm"
            class="my-auto"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <font-awesome-icon :icon="['far', 'chevron-left']" />
            </template>
            <template #next-text>
              <font-awesome-icon :icon="['far', 'chevron-right']" />
            </template>
          </b-pagination>
        </div>
        <div style="width: 200px" />
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from "@axios";
import { useRouter } from "@core/utils/utils";
import { BCard, BDropdown, BDropdownItem, BDropdownForm } from "bootstrap-vue";
import TableSettingModal from "@core/components/modals/TableSettingModal";

import ClientsTable from "./tables/ClientsTable";
import AccountsTable from "./tables/AccountsTable";
import tableFields from "./tables/tableFields";
import { notifyError, notifySuccess } from "@/@core/utils";
import countriesWithCurrencies from "@/helper/countriesWithCurrencies";

export default {
  components: {
    BCard,
    BDropdown,
    BDropdownItem,
    ClientsTable,
    AccountsTable,
    BDropdownForm,
    TableSettingModal,
  },

  data() {
    return {
      itemTypes: {
        clients: {
          label: "Clients",
          route: { name: "clients" },
          table: "ClientsTable",
        },
        accounts: {
          label: "Accounts",
          route: { name: "clients_accounts" },
          table: "AccountsTable",
        },
      },
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      tableFields: tableFields,
      isDropdown2Visible: false,
      excludedFields: [],
      selected: [],
      tableKey: true,
      defaultCurrency: "USD",
      employees_op: [],
      ibs_op: [],
    };
  },

  computed: {
    currentItem() {
      return Object.keys(this.itemTypes).find(
        (key) => this.itemTypes[key].route.name == this.$route.name
      );
    },
    currentTable() {
      return this.itemTypes[this.currentItem].table;
    },
    tableCssVars() {
      return {
        "--per-page": this.perPage,
      };
    },
    getTableFields() {
      let symbol = countriesWithCurrencies.find(
        (x) => x.abbreviation == this.defaultCurrency
      ).symbol;
      tableFields.clients.find(
        (x) => x.key == "account_equity"
      ).label = `Trading (${symbol})`;
      tableFields.clients.find(
        (x) => x.key == "wallet_equity"
      ).label = `Wallet (${symbol})`;
      tableFields.clients.find(
        (x) => x.key == "total_equity"
      ).label = `Total (${symbol})`;
      return tableFields;
    },
  },

  methods: {
    search_ib_change(search, loading) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(search.toLowerCase()) || /^\d+$/.test(search))
        axios
          .get(
            `${localStorage.getItem(
              "VUE_APP_API_URL"
            )}/partners?email_or_id=${search}`
          )
          .then((res) => (this.ibs_op = res.data));
    },

    search_change(search, loading) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(search.toLowerCase()) || /^\d+$/.test(search))
        axios
          .get(
            `${localStorage.getItem(
              "VUE_APP_API_URL"
            )}/employees?email_or_id=${search}`
          )
          .then((res) => (this.employees_op = res.data));
    },

    onClick(value, index) {
      if (this.$route.name != value.route.name) {
        this.$router.push({ name: value.route.name });
        this.resetExcludedFields();
      }
    },
    setExcludedFields(fields) {
      localStorage.setItem(
        "table_excluded_fields_" + this.currentItem,
        JSON.stringify(fields)
      );
      this.$router.go(0);
    },
    resetExcludedFields() {
      let fromLocalStorage = localStorage.getItem(
        "table_excluded_fields_" + this.currentItem
      );
      this.excludedFields = JSON.parse(fromLocalStorage) || [];
    },
    onClickAssign(action) {
      if (action.onclick) {
        this.$bvModal.show(action.onclick.modalID);
      }
    },
    assignClientAM(item) {
      console.log("Selected: ", item);
      let loading = this.$loading.show();
      axios
        .post(`${localStorage.getItem("VUE_APP_API_URL")}/clients/assign`, {
          client: {
            principal_type: "user",
            principal_id: item,
            client_ids: this.selected,
          },
        })
        .then((res) => {
          notifySuccess("Success", "Successfully assigned clients to AM");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to assign clients");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    assignClientIB(item) {
      console.log("Selected: ", item);
      let loading = this.$loading.show();
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/partners/${item}/assign_client_to_ib`,
          {
            client_ids: this.selected,
          }
        )
        .then((res) => {
          notifySuccess("Success", "Successfully assigned clients to IB");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to assign clients to IB");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    assignAccountIB(item) {
      console.log("Selected: ", item);
      let loading = this.$loading.show();
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/partners/${item}/assign_account_to_ib`,
          {
            account_ids: this.selected,
          }
        )
        .then((res) => {
          notifySuccess("Success", "Successfully assigned accounts to IB");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to assign accounts to IB");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    onClickUnassign(action) {
      if (action.onclick) {
        console.log(action.onclick.method);
        this[action.onclick.method](...(action.onclick.params || []));
      }
    },
    unassignAccountIB(item) {
      console.log("Selected: ", item);
      let loading = this.$loading.show();
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/partners/${item}/unassign_account_from_ib`,
          {
            account_ids: this.selected,
          }
        )
        .then((res) => {
          notifySuccess("Success", "Successfully unassigned accounts from IB");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to unassign accounts from IB");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    unassignClientIB(item) {
      console.log("Selected: ", item);
      let loading = this.$loading.show();
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/partners/${item}/unassign_client_from_ib`,
          {
            client_ids: this.selected,
          }
        )
        .then((res) => {
          notifySuccess("Success", "Successfully unassigned clients from IB");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to unassign clients from IB");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    onclickAction(action) {
      if (action.onclick) {
        this[action.onclick.method](...action.onclick.params);
      }
    },
    changeClientState(transition) {
      console.log("Selected: ", this.selected, " transit ", transition);
      let loading = this.$loading.show();
      axios
        .post(
          `${localStorage.getItem("VUE_APP_API_URL")}/clients/update_state`,
          {
            client_ids: this.selected,
            transition,
          }
        )
        .then((res) => {
          notifySuccess("Success", "Successfully changed state of clients");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to changed state of clients");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    changeWalletState(transition) {
      console.log("Selected: ", this.selected, " transit ", transition);
      let loading = this.$loading.show();
      axios
        .post(
          `${localStorage.getItem("VUE_APP_API_URL")}/wallets/update_state`,
          {
            wallet_ids: this.selected,
            transition,
          }
        )
        .then((res) => {
          notifySuccess("Success", "Successfully changed state of wallets");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to changed state of wallets");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    changeAccountState(transition) {
      console.log("Selected: ", this.selected, " transit ", transition);
      let loading = this.$loading.show();
      axios
        .post(
          `${localStorage.getItem("VUE_APP_API_URL")}/accounts/update_state`,
          {
            account_ids: this.selected,
            transition,
          }
        )
        .then((res) => {
          notifySuccess("Success", "Successfully changed state of accounts");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to changed state of accounts");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    changeAccountReadonly(read_only) {
      let loading = this.$loading.show();
      return axios
        .post(
          `${localStorage.getItem("VUE_APP_API_URL")}/accounts/update_readonly`,
          {
            account_ids: this.selected,
            read_only: read_only,
          }
        )
        .then((res) => {
          notifySuccess(
            "Success",
            "Successfully changed accounts' readonly state"
          );
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError(
            "Error",
            err.response.data.errors ||
              "Fail to change accounts' readonly state"
          );
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    changeAccountAutoTransfer(auto_transfer) {
      let loading = this.$loading.show();
      return axios
        .patch(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/accounts/update_auto_transfer_settings`,
          {
            account_ids: this.selected,
            auto_transfer,
          }
        )
        .then((res) => {
          notifySuccess(
            "Success",
            "Successfully changed accounts' auto-transfer settings"
          );
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError(
            "Error",
            err.response.data.errors ||
              "Fail to change accounts' auto-transfer settings"
          );
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    changeWalletAutoTransfer(auto_transfer) {
      let loading = this.$loading.show();
      return axios
        .patch(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/wallets/update_auto_transfer_settings`,
          {
            wallet_ids: this.selected,
            auto_transfer,
          }
        )
        .then((res) => {
          notifySuccess(
            "Success",
            "Successfully changed wallets' auto-transfer settings"
          );
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError(
            "Error",
            err.response.data.errors ||
              "Fail to change wallets' auto-transfer settings"
          );
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
  },

  mounted() {
    this.$root.$on("bv::dropdown::show", (bvEvent) => {
      if (bvEvent.componentId.includes("dropdown-2")) {
        this.isDropdown2Visible = true;
      }
    });
    this.$root.$on("bv::dropdown::hide", (bvEvent) => {
      if (bvEvent.componentId.includes("dropdown-2")) {
        this.isDropdown2Visible = false;
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault();
      }
    });
    this.resetExcludedFields();

    // axios.get(`${localStorage.getItem("VUE_APP_API_URL")}/employees`).then((res) => {
    //   this.employees = res.data.map((x) => {
    //     return {
    //       value: x.user.id,
    //       text: `${x.user.id} - ${x.user.full_name} - ${x.position_title}`
    //     }
    //   })
    // }).catch(err => {
    //   console.error(err)
    // }).finally(() => {
    // })

    // axios.get(`${localStorage.getItem("VUE_APP_API_URL")}/partners`).then(res => {
    //   this.ibs = []
    //   this.ibs.push(...res.data.map(x => ({
    //     text: `${x.id} - ${x.user.full_name} - ${x.commission_profile.name}`,
    //     value: x.id,
    //   })))
    // }).catch(err => {
    //   console.error(err)
    // }).finally(() => {

    // })
  },

  // async created() {
  //   let res = await axios.get(
  //     `${localStorage.getItem("VUE_APP_API_URL")}/site_settings?recursive`,
  //     {
  //       params: {
  //         path: "company",
  //       },
  //     }
  //   );
  //   this.defaultCurrency = res.data.settings.main_currency;
  //   this.tableFields = this.getTableFields
  // },
  destroyed() {
    this.$store.commit("app/SET_SIDEBAR_WIDTH", 0);
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>

<style>
.children-dropdown {
  display: flex;
  padding: 0;
  justify-content: space-between;
  width: 100%;
}
</style>
